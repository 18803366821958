import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import pdf from "../../assets/images/FooterImages/communityguidelines.pdf";
import { useSelector } from "react-redux";
import Logo from "assets/images/FooterImages/footerlogo.svg";
import DarkLogo from "assets/images/FooterImages/darkFooterlogo.svg";
import { FaLinkedin, FaGithub } from "react-icons/fa";
// import Map from "assets/images/FooterImages/map.png";

import { ReactComponent as Phone } from "assets/images/FooterImages/phone.svg";
import { ReactComponent as Gmail } from "assets/images/FooterImages/gmail.svg";
import { ReactComponent as Insta } from "assets/images/FooterImages//instagram.svg";
import { ReactComponent as Twitter } from "assets/images/FooterImages/twitter.svg";
// import { ReactComponent as Github } from "assets/images/FooterImages/github.svg";
import { ReactComponent as Youtube } from "assets/images/FooterImages/youtube.svg";
// import { ReactComponent as Linkedin } from "assets/images/FooterImages/linkedin.svg";
import { ReactComponent as Discord } from "assets/images/FooterImages/discord.svg";

const BigContainer = tw.div`p-12 bg-white dark:bg-darkmodebackground dark:text-white`;
const Container = tw.div`sm:px-4 2xl:px-0 pt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  justify-items-center`;
const Section = tw.div`flex flex-col w-full md:w-auto	 items-center text-lg`;
const Para = tw.p`text-gray-600 leading-snug text-center text-base dark:text-white`;
const Heading = tw.h1`text-2xl text-gray-600 font-medium mb-4 md:mb-14 dark:text-white `;
const FlexCon = tw.div`flex flex-col`;
const InnCon = tw.a`flex items-center pb-4`;
const SocialLink = styled.a`
  ${tw`p-1 mr-4 bg-white rounded-full shadow hocus:shadow-lg last:mr-0 dark:bg-darkmodebackground1`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const FooterGrid = () => {
  const Mode = useSelector((state) => state.mode);
  return (
    <BigContainer>
      <Container className='pb-12 border-b-2 border-gray-300 '>
        <Section className='max-w-sm '>
          {Mode === "light" ? (
            <img src={Logo} className='mb-4 md:mb-12' alt='GDSC_LOGO' />
          ) : (
            <img src={DarkLogo} className='mb-4 md:mb-12' alt='GDSC_LOGO' />
          )}
          <Para className='text-base font-poppins dark:text-white'>
            We are here at GDSC VIT to bridge the gap between your emerging
            Ideas and the changing Reality. Let's Connect, Learn and Grow together
            to emerge as the Best.
            <br /> <br />
            Built with love by <b> Web Team GDSC-VIT </b>
          </Para>
        </Section>
        <Section className='mt-20 md:mt-auto'>
          <Heading>Get In Touch</Heading>
          <FlexCon>
            <InnCon
              href='tel:+918879379763'>
              <Phone className='w-6 mr-4 text-blue-core' />
              <Para>+91 8879379763</Para>
            </InnCon>
            <InnCon
              rel='noopener noreferrer'
              target='_blank'
              href='mailto:dsc.vidyalankar@gmail.com'>
              <Gmail className='w-6 mr-6' />
              <Para>dsc.vidyalankar@gmail.com</Para>
            </InnCon>
            <InnCon
              href='tel:+919819939332'>
              <Phone className='w-6 mr-4 text-blue-core' />
              <Para>+91 9819939332</Para>
            </InnCon>
            <InnCon
              rel='noopener noreferrer'
              target='_blank'
              href='mailto:gdsc@vit.edu.in'>
              <Gmail className='w-6 mr-6' />
              <Para>gdsc@vit.edu.in</Para>
            </InnCon>
          </FlexCon>
        </Section>
        <Section className='mt-20 md:col-span-3 lg:col-auto md:mt-24 lg:mt-auto'>
          <Para className='pt-2 mb-4 text-xl font-medium md:mb-14 md:text-base md:font-normal '>
            <b>Find Us :</b> Vidyalankar Institute of Technology
          </Para>
          {/* <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://goo.gl/maps/xUjH9cS7SBo9UQ2E7"
          >
            <img src={Map} alt="Map" className="max-h-44" />
          </a> */}
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1557.0339308984012!2d72.87007284337682!3d19.021590525651078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf3949dda405%3A0xefd46c9d73152f82!2sVIDYALANKAR%20INSTITUTE%20OF%20TECHNOLOGY%2C%20Mumbai%2C%20Maharashtra%20400037!5e0!3m2!1sen!2sin!4v1637072007496!5m2!1sen!2sin'
            width='300'
            height='200'
            title='map'
            allowFullScreen=''
            loading='lazy'></iframe>
        </Section>
      </Container>
      <Container className='place-items-center '>
        <Para className='text-lg '> &copy; Google DSC VIT Mumbai 2023-24</Para>
        <Section className='mt-20 lg:-mt-8 md:mt-12 md:order-3 lg:order-2 md:col-span-3 lg:col-auto'>
          <Para className='mb-2 '>Lets Get Social</Para>
          <div className='flex '>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://www.linkedin.com/company/gdsc-vit-mumbai/'>
              {/* <Linkedin /> */}
              <FaLinkedin className='pb-1 text-social-linkedin ' />
            </SocialLink>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://www.youtube.com/channel/UCl93weektPDXfHC_sklxnrg?sub_confirmation=1'>
              <Youtube />
            </SocialLink>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://github.com/GoogleDscVit'>
              <FaGithub className='pb-1 dark:text-white' />
              {/* <Github className='dark:text-white' /> */}
            </SocialLink>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://twitter.com/gdsc_vit'>
              <Twitter />
            </SocialLink>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://www.instagram.com/gdsc.vit/'>
              <Insta />
            </SocialLink>
            <SocialLink
              rel='noopener noreferrer'
              target='_blank'
              href='https://discord.gg/fZK6DdGg'>
              <Discord />
            </SocialLink>
          </div>
        </Section>

        <Para className='mt-20 text-lg font-extrabold duration-300 transform border-b-2 border-transparent hover:border-black hover:-translate-y-1 md:order-2 lg:order-3 md:mt-auto'>
          <a href={pdf} target='_blank' rel='noopener noreferrer'>
            Community GuideLines
          </a>
        </Para>
      </Container>
      <div className='pt-10 text-center'>
        <p className='text-xs md:order-2 lg:order-3 md:mt-auto'>
          All trademarks, service marks, trade names, product names, logos and
          trade dress appearing on our website are the property of their
          respective owners.
        </p>
      </div>
    </BigContainer>
  );
};

export default FooterGrid;
