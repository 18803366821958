import React from "react";
import { FaArrowRight } from "react-icons/fa";
const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute block cursor-pointer bg-slickBackground-color z-1 top-1/2 -right-3 dark:text-white"
      onClick={onClick}
    >
      <FaArrowRight />
    </div>
  );
};

export default NextArrow;
