import React, { useState } from "react";
import "./Landing.css";
import { useSelector } from "react-redux";
import Viewer from "bigGModel/model";
import { Header } from "components/navbar/Header";
import { NewsletterModal } from "./Modal.js";

const Landing = () => {
  const Mode = useSelector((state) => state.mode);
  const currentMode = useSelector((state) => state.mode);
  const [email, setEmail] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };
  const handleJoinUsClick = async () => {
    if (email === "") {
      alert("Please enter your E-Mail ID to join! 🥺");
    } else if (!validateEmail(email)) {
      alert("Enter a valid E-Mail address! 😵");
    } else {
      /*
        Google Sheet Endpoint is generated from No Code API
        https://app.nocodeapi.com/dashboard/api/google_sheets
        Signed in with Kaushal Joshi's account (kaushalyan.99@gmail.com)
       */
      try {
        const response = await fetch(
          /*"https://v1.nocodeapi.com/clumsycoder/google_sheets/xMuwokNFjkQincJF?tabId=Sheet1",*/
          "https://v1.nocodeapi.com/gdsc/google_sheets/bWwbXcfwkPnqxSuy?tabId=Sheet1",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify([[email]]), // NoCodeAPI needs 2D Array
          }
        );

        await response.json();
        setIsOpen(true);
        setEmail("");
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div className={currentMode}>
      <div id='home' className='h-auto md:pb-32 dark:bg-darkmodebackground '>
        <Header key='header_in_landing' />
        <div className='container xl:pt-32 lg:pt-40 pt-14' key='landingPage'>
          <div className='flex flex-col-reverse w-full p-10 md:gap-36 sm:p-0 sm:pl-6 xl:flex-row lg:flex-row'>
            <div className='w-full xl:w-2/4 xl:mt-20 lg:mt-20 md:mt-5 sm:mt-5'>
              <div className='w-full mb-10'>
                <p className='pb-1 pl-1 text-sm font-bold xl:text-lg lg:text-lg xl:pb-2 lg:pb-2 dark:text-white'>
                  Our Aim
                </p>

                <div className='block w-full h-auto'>
                  <p className='block xl:text-exl lg:text-exsl sm:text-md md:text-xl xs:text-mfont text-lfont'>
                    <span className='title-word title-word-1'>Converting</span>
                    <span className='ml-1 title-word title-word-2'>Ideas</span>
                    <span className='ml-1 title-word title-word-3'>Into</span>
                    <span className='ml-1 title-word title-word-4'>Reality!</span>
                  </p>
                </div>
                <div className='w-full pl-1 mt-3 mb-3 text-xs xl:text-lg lg:text-lg md:text-md sm:text-landingfont 2xl:pt-5 xl:pt-5 lg:pt-5 dark:text-white'>
                  <p className='flex flex-col text-xs xl:text-lg lg:text-lg md:text-sm sm:text-sm'>
                    <span className='pb-2'>
                      Welcome to the first ever chapter of Google Developer
                      Student Clubs - VIT. Students develop their skills in this
                      thriving environment and build solutions for organizations
                      and the community.
                    </span>

                    <span className='pb-2 text-bold'>
                      Be the part of this growing community now !
                    </span>
                  </p>
                </div>

                <div
                  className={`mt-5 w-full xl:w-full  p-1 rounded-xl text-left flex justify-between 2xl:mt-10 xl:mt-10 lg:mt-10 sm:mt-5 dark:bg-darkmodecardbackground ${
                    Mode === "light" ? "border" : "border-none"
                  }`}>
                  <input
                    id='joinus-mail'
                    className='w-full p-2 ml-2 text-sm font-bold xl:w-4/5 lg:w-full xl:text-lg lg:text-lg md:text-xs focus:outline-none dark:bg-darkmodecardbackground dark:text-white '
                    type='email'
                    name='joinus-mail'
                    placeholder='Enter your Email-ID'
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                  />
                  <button
                    onClick={handleJoinUsClick}
                    className='w-24 font-bold text-white bg-blue-500 rounded-md cursor-pointer xl:m-1 xl:pl-2 lg:p-1 xl:w-30 xl:rounded-lg lg:rounded-lg xl:text-lg lg:text-md text-join'>
                    Join Us
                  </button>
                </div>
              </div>
            </div>
            <div
              className='block h-mheight md:h-lheight md:w-full'
              style={{ overflow: "hidden" }}>
              {/* Adding image instead of 3D model */}
              {/* {currentMode === "dark" ? (
                <img src={landingimage} alt="Landing Dark" />
              ) : (
                <img src={landingimage} alt="Landing Light" />
              )} */}
              <Viewer />
            </div>
            <NewsletterModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
