import React from "react";
import logo from "../../assets/images/LinkedInImage/LinkedIn_logo.png";
import "./teams.css";

const Slides = ({ name, status, idx, imageIndex, image, linkedin }) => {
  return (
    <div className='w-full lg:w-64 md:w-64 p-2 pb-10 xl:mt-10 xl:p-0 lg:p-0 md:p-0 sm:p-0 font-mono md:mb-10'>
      <div
        className={
          idx === imageIndex
            ? "transform scale-90 block shadow-2xl bg-white mx-auto rounded-md h-88 w-64 dark:bg-black"
            : "transform scale-75  transition duration-300 opacity-100 rounded-md shadow-lg  bg-white"
        }>
        <div className='rounded-md transform hover:scale-110 hover:bg-green-200 transition duration-700'>
          <div
            className={
              idx === imageIndex
                ? "flex bg-cover h-52  md:h-52 xl:h-56  rounded-t-md opacity-100 w-full dark:bg-black dark:hover:bg-lime-300"
                : "flex bg-cover h-52  md:h-52 xl:h-56 w-full rounded-t-md opacity-50 bg-blue-400"
            }
            style={{ justifyContent: "center" }}>
            <img
              src={image}
              alt={name}
              loading='lazy'
              className='object-cover h-48 w-48 rounded-full shadow-2xl mt-6 bg-white'
            />
          </div>
          <div className='dark:bg-black'>
            <div
              className={
                idx === imageIndex
                  ? "font-bold text-lg  p-2 md:text-md lg:text-lg xl:text-lg my-2 text-center text-black dark:text-white "
                  : "font-bold text-lg  p-2 md:text-md lg:text-lg xl:text-lg  my-2 text-center dark:text-white "
              }>
              {name}
            </div>
            <p
              className={
                idx === imageIndex
                  ? "text-black text-md sm:text-md md:text-sm lg:text-sm xl:text-sm text-center dark:text-white"
                  : "text-gray-700 text-md sm:text-md md:text-sm lg:text-sm xl:text-sm text-center dark:text-black"
              }>
              {status}
            </p>
            {
              <a
                target='_blank'
                href={linkedin}
                className={
                  idx === imageIndex
                    ? "text-black cursor-pointer flex justify-center pt-3 pb-2 text-md"
                    : "text-blue-500 cursor-pointer flex justify-center pt-3 text-md"
                }
                rel='noreferrer'>
                <img src={logo} alt='LinkedIn Logo' className='rounded-full h-10' />
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slides;
