import React from "react";

const Header = () => {
  return (
    <div className='block py-10' id='ourTeam'>
      <div className='block text-xl text-center sm:text-2xl lg:text-3xl'>
        <h1 className='text-2xl font-bold text-center text-topcolor xl:text-4xl lg:text-4xl dark:text-white'>
          Our <span className='text-blue-core'>Team</span>
        </h1>
      </div>
    </div>
  );
};

export default Header;
