import React, { useState } from "react";
import Slider from "react-slick";

import NextArrow from "./NextArrow";
import PrevArrow from "./PreviousArrow";
import Slides from "./Eventsslides";

import Cloud21 from "../../assets/images/OurEventsImages/2021 - 2022/Cloud_2021.svg";
import Design101 from "../../assets/images/OurEventsImages/2021 - 2022/Design101.svg";
import WorldOfDSA from "../../assets/images/OurEventsImages/2021 - 2022/World_Of_DSA.svg";

import ComposeCamp22 from "../../assets/images/OurEventsImages/2022 - 2023/ComposeCamp.svg";
import Speaker_ComposeCamp22 from "../../assets/images/OurEventsImages/2022 - 2023/Speaker_ComposeCamp.svg";
import GCCP22 from "../../assets/images/OurEventsImages/2022 - 2023/GCCP_2022.svg";

import CodeCatalyst from "../../assets/images/OurEventsImages/2023 - 2024/Code_Catalyst.svg";
import CloudStudyJam23 from "../../assets/images/OurEventsImages/2023 - 2024/Cloud_Study_Jam_2023.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderFunction = () => {
  const information = [
    {
      id: 1,
      event: `${Cloud21}`,
      link: "https://www.youtube.com/watch?v=JOpxDhBKQSw",
    },
    {
      id: 2,
      event: `${Design101}`,
      link: "https://www.youtube.com/watch?v=Ql6vL9AiLdU",
    },
    {
      id: 3,
      event: `${WorldOfDSA}`,
      link: "https://www.youtube.com/channel/UCl93weektPDXfHC_sklxnrg",
    },
    {
      id: 4,
      event: `${ComposeCamp22}`,
      link: "https://gdsc.community.dev/events/details/developer-student-clubs-vidyalankar-institute-of-technology-mumbai-presents-jetpack-compose-camps-day-1/",
    },
    {
      id: 5,
      event: `${Speaker_ComposeCamp22}`,
      link: "https://gdsc.community.dev/events/details/developer-student-clubs-vidyalankar-institute-of-technology-mumbai-presents-speaker-session-chintan-parmar-compose-camps-gdsc-vit/",
    },
    {
      id: 6,
      event: `${GCCP22}`,
      link: "https://gdsc.community.dev/events/details/developer-student-clubs-vidyalankar-institute-of-technology-mumbai-presents-gccp-info-session/",
    },
    {
      id: 7,
      event: `${CodeCatalyst}`,
      link: "https://gdsc.community.dev/events/details/developer-student-clubs-vidyalankar-institute-of-technology-mumbai-presents-code-catalyst-induction/",
    },
    {
      id: 8,
      event: `${CloudStudyJam23}`,
      link: "https://gdsc.community.dev/events/details/developer-student-clubs-vidyalankar-institute-of-technology-mumbai-presents-google-cloud-study-jams/",
    }
  ];

  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  return (
    <div
      id='events'
      className=' bg-blue-50 bg-slickBackground dark:bg-darkmodebackground1 pt-16 pb-16'>
      <div className='container'>
        <div className='px-10'>
          <p className='text-center text-4xl font-bold text-gray-700 dark:text-white'>
            Our <span className='text-yellow-400'>Events</span>
          </p>
          <p className='text-center text-gray-600 pb-8 dark:text-white'>
            You landed on our 'Promised land', created by the club and for the
            club!
            <br />
            Channelize your skills in the right direction along with inclusive
            learning and disseminating information.
            <br />
          </p>
        </div>
        <Slider key='slide' {...settings} className='mx-2 px-2'>
          {information.reverse().map((info, idx, id) => (
            <Slides
              key={id}
              idx={idx}
              imageIndex={imageIndex}
              event={info.event}
              link={info.link}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderFunction;
