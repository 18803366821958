import React from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("/the_big_g_building.gltf");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.1}>
        <mesh
          receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials.mat6}
        />
        {/* scale={[0.5, 0.4, 0.4]} */}
        <mesh
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials.mat10}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_4.geometry}
          material={materials.mat11}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_5.geometry}
          material={materials.mat12}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_6.geometry}
          material={materials.mat13}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_7.geometry}
          material={materials.mat14}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_8.geometry}
          material={materials.mat15}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_9.geometry}
          material={materials.mat16}
        />
        {/* support rods */}
        <mesh
          receiveShadow
          geometry={nodes.Object_10.geometry}
          material={materials.mat17}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_11.geometry}
          material={materials.mat18}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_12.geometry}
          material={materials.mat19}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_13.geometry}
          material={materials.mat2}
        />
        {/* stage - brown base */}
        {/* <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_14.geometry}
          material={materials.mat20}
        /> */}
        <mesh
          receiveShadow
          geometry={nodes.Object_15.geometry}
          material={materials.mat21}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_16.geometry}
          material={materials.mat22}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_17.geometry}
          material={materials.mat23}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_18.geometry}
          material={materials.mat24}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_19.geometry}
          material={materials.mat3}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_20.geometry}
          material={materials.mat4}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_21.geometry}
          material={materials.mat5}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_22.geometry}
          material={materials.mat7}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_23.geometry}
          material={materials.mat8}
        />
        <mesh
          receiveShadow
          geometry={nodes.Object_24.geometry}
          material={materials.mat9}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/the_big_g_building.gltf");
