import React from "react";
import Slider from "react-slick";
import { partners, partnersDark } from "../../assets/data/partners";
import { useSelector } from "react-redux";
import PrevArrow from "./PreviousArrow";
import NextArrow from "./NextArrow";
import "../../App.css";

const settings = {
  className: "center",
  infinite: true,
  centerPadding: "100px",
  leftPadding: "50px",
  slidesToShow: 4,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  afterChange: function (index) {},
};

function Partners(props) {
  const Mode = useSelector((state) => state.mode);

  return (
    <div
      id='partners'
      className='flex flex-col pt-16 pb-16 bg-slickBackground dark:bg-darkmodebackground1'>
      <h1 className='text-2xl font-bold text-center text-topcolor xl:text-4xl lg:text-4xl dark:text-white'>
        Our <span className='font-bold text-red-core'> Partners</span>
      </h1>
      <div className='mt-4 text-center xl:place-self-end xl:mr-8 xl:pr-8 lg:place-self-end lg:mr-8 lg:pr-8 dark:text-white'>
        Interested in Partnering?
      </div>
      <div className='text-center xl:place-self-end xl:mr-8 xl:pr-8 lg:place-self-end lg:mr-8 lg:pr-8 dark:text-white'>
        Contact us:{" "}
        <a href='mailto:gdsc@vit.edu.in' className='text-blue-core'>
          dsc.vidyalankar@gmail.com
        </a>
      </div>

      {/* <hr className="m-6" /> */}
      <div className='container' style={{ paddingTop: "3rem" }}>
        <Slider {...settings}>
          {Mode === "dark"
            ? partnersDark.map((partner, index) => (
                <div key={index * 10}>
                  <div
                    className='flex justify-center px-3 mx-auto my-auto '
                    key={partner.id}>
                    <div className='w-full max-w-xs overflow-hidden transition-shadow duration-300 ease-in-out bg-white border-2 border-blue-300 border-solid rounded-lg shadow-xl sm:w-210 hover:shadow-2xl dark:bg-black'>
                      <img
                        className='object-contain object-center w-full pl-3 pr-3 rounded h-135'
                        src={partner.src}
                        alt={partner.heading}
                      />
                    </div>
                  </div>
                </div>
              ))
            : partners.map((partner, index) => (
                <div key={index * 10}>
                  <div
                    className='flex content-center justify-center px-3 mx-auto my-auto '
                    key={partner.id}>
                    <div className='w-full max-w-xs p-2 overflow-hidden transition-shadow duration-300 ease-in-out bg-white border-2 border-blue-300 border-solid rounded-lg shadow-xl sm:w-210 hover:shadow-2xl dark:bg-black'>
                      <img
                        className='object-contain object-center w-full pl-3 pr-3 rounded h-135'
                        src={partner.src}
                        alt={partner.heading}
                      />
                    </div>
                  </div>
                </div>
              ))}
        </Slider>
      </div>
    </div>
  );
}

export default Partners;
