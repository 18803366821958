import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";
import { Model } from "./Scene";

export default function Viewer() {
  const ref = useRef();
  return (
    <Canvas flat dpr={[1, 2]} camera={{ fov: 50, zoom: 0.7 }}>
      <Suspense fallback={null}>
        <Stage
          controls={ref}
          preset='rembrandt'
          intensity={1}
          environment='lobby'>
          false
          <Model />
          false
        </Stage>
      </Suspense>
      <OrbitControls ref={ref} autoRotate />
    </Canvas>
  );
}
