import React from "react";
const Slides = ({ idx, imageIndex, event, link }) => {
  return (
    <div className="flex flex-col justify-center">
      <img
        src={event}
        alt="events"
        className={
          idx === imageIndex
            ? " transform scale-90 w-full block mx-auto md:w-full lg:w-full xl:w-full sm:w-4/6 sm:block sm:mx-auto  opacity-100  rounded"
            : "transform scale-75 w-full transition duration-300 opacity-100 rounded"
        }
      />
      <div className="px-2 py-1 m-2 mx-auto my-0 mb-2 text-white transition ease-in-out border rounded-md darkmodebackground1ation-300 bg-blue-core hover:bg-slickBackground hover:text-blue-core hover:border-blue-core dark:hover:text-white dark:border-blue-core dark:hover:bg-darkmodebackground1">
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >Know More! </a>
      </div>
    </div>
  );
};

export default Slides;
