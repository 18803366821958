import React, { useState, useEffect } from "react";
import Header from "./BlogHeader";
import Blog from "./Blog";
import { GET_BLOGS_QUERY, getBlogs } from "./query";

let blogs = [];

const Blogs = () => {
  const [blogsFetched, setBlogsFetched] = useState(false);

  useEffect(() => {
    getBlogs(GET_BLOGS_QUERY, { page: 1 }).then((result) => {
      blogs = result.data.user.posts.edges;
      setBlogsFetched(true);
      // console.log(blogs);
    });
  }, []);

  return (
    <div className='outer-fordark dark:bg-darkmodebackground1'>
      <div className='container pt-20 pb-20' id='blogs'>
        <Header />
        <div className='grid grid-cols-1 gap-4 mx-auto 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2'>
          {blogsFetched &&
            blogs.map((blog) => (
              <Blog
                key={blog.node.id}
                id={blog.node.id}
                title={blog.node.title}
                slug={blog.node.slug}
                thumbnail={blog.node.coverImage.url}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
