export const GET_BLOGS_QUERY = `
  query GetUserArticles($page: Int!) {
    user(username: "gdscvit") {
      posts(page: $page, pageSize: 6, sortBy: DATE_PUBLISHED_DESC) {
        edges {
          node {
            id
            title
            slug
            coverImage {
              url
            }
          }
        }
      }
    }
  }
`;


export const getBlogs = async (query, variables = {}) => {
	const data = await fetch('https://gql.hashnode.com/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			query,
			variables
		})
	});

	return data.json();
}
