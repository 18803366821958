import React from "react";

const parseAuthorName = (slug) => {
  if (!slug.includes("-byy-")) {
    return;
  }

  let arr = slug.split("byy-");
  let name = arr[1].replace("-", " ");

  return name;
};

const Blog = ({ id, title, author, slug, thumbnail }) => {
  const authorName = parseAuthorName(slug);

  // TODO: Change the URL
  const url = `https://gdscvit.hashnode.dev//${slug}`;

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="w-full rounded overflow-hidden shadow-xl mt-10  h-blogCardHeight dark:bg-darkmodebackground1 ">
        {/* <img className="w-full" src="/img/card-top.jpg" alt="Sunset in the mountains"> */}
        {/* <div className="w-full h-42"> */}
        <img src={thumbnail} alt={title} className="w-full  max-h-52" />
        {/* </div> */}
        <div className="px-6 py-4 pb-5">
          <div className="font-bold text-xl my-2 dark:text-white">{title}</div>
          <p className="text-gray-700 text-base text-justify capitalize dark:text-white">
            {authorName ? authorName : author}
          </p>
        </div>
      </div>
    </a>
  );
};

export default Blog;
