import React, { useState } from "react";
import FlipCardList from "./FlipCardList";
import "./flipcard.css";
import learn from "../../assets/images/OurIdeasImages/learn.png";
import connect from "../../assets/images/OurIdeasImages/connect.png";
import grow from "../../assets/images/OurIdeasImages/grow.png";
import build from "../../assets/images/OurIdeasImages/build.png";

function FlipCard() {
  const [cards] = useState(CONTENT);
  return (
    <div
      id='ideas'
      className='p-4 pt-16 pb-16 m-auto bg-slickBackground dark:bg-darkmodebackground1'>
      <div className='container'>
        <div className='header'>
          <h1 className='text-2xl font-normal text-center text-topcolor xl:text-4xl lg:text-4xl dark:text-white'>
            Our <span className='font-bold '>Ideas</span>
          </h1>
          <p className='w-full p-3 m-auto text-sm text-center text-paracolor dark:text-white'>
            Ideas shaping the solutions and converting into reality
            <br />
            for a better future and experience.
            {/* <br /> bring together students, developers and communities
            <br /> under the same roof. */}
          </p>
        </div>
        <div className='max-full  mt-0.5 mb-0.5'>
          <FlipCardList cards={cards} />
        </div>
      </div>
    </div>
  );
}
const CONTENT = [
  {
    id: 1,
    image: connect,
    title: "Connect",
    content:
      "We invite everyone! Get a wonderful chance to connect with like minded Developers of diverse domains and exchange your Ideas.",
  },

  {
    id: 2,
    image: learn,
    title: "Learn",
    content:
      "We organise non-stop insightful events including workshops and seminars to ensure that your journey of learning never stops!",
  },

  {
    id: 3,
    image: build,
    title: "Build",
    content:
      "With skill you can build great solutions for local problems. We unite here with different ideas that gives shape to a solution.",
  },

  {
    id: 4,
    image: grow,
    title: "Grow",
    content:
      "You grow with knowledge. Improve your skill, advance your career and develop your network and finally contribute to your community.",
  },
];

export default FlipCard;
