import React, { useState } from "react";
import { information } from "../../assets/data/teaminfo";
import Card from "components/teamtab/TeamCard";
import { useEffect } from "react";
import "./teams.css";
import Header from "./tabHeader";

function FilterCard() {
  const [items, setItem] = useState([]);
  const [convener, setConvener] = useState(false);
  const [lead, setLead] = useState(true);
  const [community_manager, setCommunityManager] = useState(false);
  const [tech, setTech] = useState(false);
  const [web, setWeb] = useState(false);
  const [design, setDesign] = useState(false);
  const [events, setEvents] = useState(false);
  const [pr, setPr] = useState(false);
  const [media, setMedia] = useState(false);
  const [doc, setDoc] = useState(false);
  const bgcolor = "linear-gradient(45deg, #05abe0 0%, #8200f4 100%)";

  useEffect(() => {
    filterItem("lead");
  }, []);

  const filterItem = (category) => {
    // console.log(category);
    const filterData = information.filter((filtdata) => {
      return filtdata.identity === category;
    });
    setItem(filterData);
  };

  return (
    <>
      <div className='outer-dark dark:bg-darkmodebackground1'>
        <Header />
        <div className='container mt-2'>
          <div
            className='h-full p-2 flex flex-wrap justify-evenly rounded-full text-gray-900 dark:bg-darkmodebackground1'
            style={{
              boxShadow:
                "10px 0px 15px 2px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("convener");
                setConvener(true);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: convener ? bgcolor : "none",
                color: convener ? "white" : null,
              }}>
              Convener
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("lead");
                setConvener(false);
                setLead(true);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: lead ? bgcolor : "none",
                color: lead ? "white" : null,
              }}>
              Leads
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("community_manager");
                setConvener(false);
                setLead(false);
                setCommunityManager(true);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: community_manager ? bgcolor : "none",
                color: community_manager ? "white" : null,
              }}>
              Community Manager
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("tech");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(true);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: tech ? bgcolor : "none",
                color: tech ? "white" : null,
              }}>
              Technical
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("web");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(true);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: web ? bgcolor : "none",
                color: web ? "white" : null,
              }}>
              Web Development
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("design");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(true);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: design ? bgcolor : "none",
                color: design ? "white" : null,
              }}>
              Design
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("events");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(true);
                setPr(false);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: events ? bgcolor : "none",
                color: events ? "white" : null,
              }}>
              Events & Operations
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("publicity");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(true);
                setMedia(false);
                setDoc(false);
              }}
              style={{
                backgroundImage: pr ? bgcolor : "none",
                color: pr ? "white" : null,
              }}>
              Public Relations
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("media");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(true);
                setDoc(false);
              }}
              style={{
                backgroundImage: media ? bgcolor : "none",
                color: media ? "white" : null,
              }}>
              Media Creation
            </button>

            <button
              className='font-bold px-4 py-2 rounded-full dark:text-white'
              onClick={() => {
                filterItem("doc");
                setConvener(false);
                setLead(false);
                setCommunityManager(false);
                setTech(false);
                setWeb(false);
                setDesign(false);
                setEvents(false);
                setPr(false);
                setMedia(false);
                setDoc(true);
              }}
              style={{
                backgroundImage: doc ? bgcolor : "none",
                color: doc ? "white" : null,
              }}>
              Documentation
            </button>
          </div>
        </div>

        <div className='justflex'>
          {items.map((ele) => {
            const { name, status, image, linkedin, id } = ele;
            return (
              <>
                <Card
                  name={name}
                  status={status}
                  image={image}
                  linkedin={linkedin}
                  key={id}
                />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default FilterCard;
