import Sachin from "../images/TeamImages/SachinSir.png";

import AmaanDhamaskar from "../images/TeamImages/Team 23-24/AmaanDhamaskar.png";
import AditiKakade from "../images/TeamImages/Team 23-24/AditiKakade.png";
import PurvaShetye from "../images/TeamImages/Team 23-24/PurvaShetye.png";

import AnuragKumbhare from "../images/TeamImages/Team 23-24/AnuragKumbhare.png";
import AbhishekDubey from "../images/TeamImages/Team 23-24/AbhishekDubey.png";

import SohamPashte from "../images/TeamImages/Team 23-24/SohamPashte.png";
import PradipGarhwal from "../images/TeamImages/Team 23-24/PradipGarhwal.png";

import AchalPatil from "../images/TeamImages/Team 23-24/AchalPatil.png";
import NadimShaikh from "../images/TeamImages/Team 23-24/NadimShaikh.png";

import AaditiManjalkar from "../images/TeamImages/Team 23-24/AaditiManjalkar.png";
import AvinashDhiran from "../images/TeamImages/Team 23-24/AvinashDhiran.png";

import MalavikaAmbike from "../images/TeamImages/Team 23-24/MalavikaAmbike.png";
import PrachetPandav from "../images/TeamImages/Team 23-24/PrachetPandav.png";

import AbhishekGadankush from "../images/TeamImages/Team 23-24/AbhishekGadankush.png";

import SejalPatil from "../images/TeamImages/Team 23-24/SejalPatil.png";

export const information = [
  {
    id: 1,
    name: "Prof. Sachin Deshpande",
    status: "Convener, GDSC VIT",
    image: `${Sachin}`,
    linkedin: "https://in.linkedin.com/in/sachin-deshpande-01a5143a",
    identity: "convener",
  },
  {
    id: 2,
    name: "Amaan Dhamaskar",
    status: "GDSC Lead",
    image: `${AmaanDhamaskar}`,
    linkedin: "https://in.linkedin.com/in/mohammed-amaan-dhamaskar",
    identity: "lead",
  },
  {
    id: 3,
    name: "Aditi Kakade",
    status: "GDSC Co-Lead",
    image: `${AditiKakade}`,
    linkedin: "https://in.linkedin.com/in/aditi-kakade-76941424a",
    identity: "lead",
  },
  {
    id: 4,
    name: "Purva Shetye",
    status: "Community Manager",
    image: `${PurvaShetye}`,
    linkedin: "https://www.linkedin.com/in/purva-shetye-283713251",
    identity: "community_manager",
  },
  {
    id: 5,
    name: "Anurag Kumbhare",
    status: "Technical Lead",
    image: `${AnuragKumbhare}`,
    linkedin: "https://in.linkedin.com/in/anurag-kumbhare-8b7715204",
    identity: "tech",
  },
  {
    id: 6,
    name: "Abhishek Dubey",
    status: "Technical Co-Lead",
    image: `${AbhishekDubey}`,
    linkedin: "https://in.linkedin.com/in/abhigns369",
    identity: "tech",
  },
  {
    id: 7,
    name: "Soham Pashte",
    status: "Web Development Lead",
    image: `${SohamPashte}`,
    linkedin: "https://in.linkedin.com/in/soham-pashte-273966228",
    identity: "web",
  },
  {
    id: 8,
    name: "Pradip Garhwal",
    status: "Web Development Co-Lead",
    image: `${PradipGarhwal}`,
    linkedin: "https://www.linkedin.com/in/pradip-garhwal-102677229",
    identity: "web",
  },
  {
    id: 9,
    name: "Achal Patil",
    status: "Design Lead",
    image: `${AchalPatil}`,
    linkedin: "https://in.linkedin.com/in/achal-patil-b70559239",
    identity: "design",
  },
  {
    id: 10,
    name: "Nadim Shaikh",
    status: "Design Co-Lead",
    image: `${NadimShaikh}`,
    linkedin: "https://in.linkedin.com/in/nadimshaikh",
    identity: "design",
  },
  {
    id: 11,
    name: "Avinash Dhiran",
    status: "Events & Operations Lead",
    image: `${AvinashDhiran}`,
    linkedin: "https://in.linkedin.com/in/avinash-dhiran",
    identity: "events",
  },

  {
    id: 12,
    name: "Aaditi Manjalkar",
    status: "Events & Operations Co-Lead",
    image: `${AaditiManjalkar}`,
    linkedin: "https://in.linkedin.com/in/aaditi-manjalkar",
    identity: "events",
  },
  {
    id: 13,
    name: "Malavika Ambike",
    status: "Public Relations Lead",
    image: `${MalavikaAmbike}`,
    linkedin: "https://in.linkedin.com/in/malavika-ambike-7705b21ba",
    identity: "publicity",
  },
  {
    id: 14,
    name: "Prachet Pandav",
    status: "Public Relations Co-Lead",
    image: `${PrachetPandav}`,
    linkedin: "https://in.linkedin.com/in/prachet-pandav-49b781225",
    identity: "publicity",
  },
  {
    id: 15,
    name: "Abhishek Gadankush",
    status: "Media Creation Lead",
    image: `${AbhishekGadankush}`,
    linkedin: "https://in.linkedin.com/in/abhishek-gadankush-55086a224",
    identity: "media",
  },
  {
    id: 16,
    name: "Sejal Patil",
    status: "Documentation Lead",
    image: `${SejalPatil}`,
    linkedin: "https://in.linkedin.com/in/sejal-patil-b53581252",
    identity: "doc",
  }
];
