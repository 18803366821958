import React, { useState } from "react";
import "./flipcard.css";
export default function Card({ card }) {
  const [flip, setFlip] = useState(false);
  return (
    <div
      className={`card ${
        flip ? "flip" : ""
      } w-full h-60 dark:bg-darkmodecardbackground`}
      onClick={() => (flip ? setFlip(false) : flip)}
    >
      <div className="frontside">
        <img className="w-16 h-16 m-auto" src={card.image} alt={card.title} />
        <div className="m-2 font-bold text-topcolor dark:text-white">
          {card.title}
        </div>
        <button
          className="py-2.5 px-8 cursor-pointer text-white rounded-lg text-sm bg-btncolor hover:bg-btncolor"
          onClick={() => setFlip(!flip)}
        >
          Learn more
        </button>
      </div>
      <div className="backside text-cardTextColor dark:text-white">
        {card.content}
      </div>
    </div>
  );
}
