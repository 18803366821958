import React from "react";

const BlogHeader = () => {
  return (
    <div className="block text-center font-bold text-black xl:text-4xl lg:text-4xl text-2xl dark:text-white">
      Our <span className="text-blue-core pr-2">Blogs</span>
    </div>
  );
};

export default BlogHeader;
